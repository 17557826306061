/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-text-color: #9b9b9b;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: transparent;
    --product-card-wishlist-button-background: transparent;
    --product-card-wishlist-button-color: var(--secondary-base-color);
    --product-card-compare-button-background: transparent;
    --product-card-compare-button-color: transparent;
    --product-card-name-line-height: 1.2em;
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 35px;
    --product-card-color-magnified-size: 40px;
    --product-card-font-family-lato: #{$font-muli};
    --product-card-button-background-color: #{$default-secondary-light-color};
    --product-card-font-color: #888888;
}

.ProductCard {
    padding: 0;
    display: flex;
    align-items: stretch;
    margin-bottom: 1.6rem;
    transition: all .2s ease-out;
    --wishlist-is-visible: none;
    --swatch-image-size: 52px;
    transition: box-shadow .3s;
    width: 100%;
    background-color: white;

    &-Information {
        &-CollectionNameSkuWrapper,
        &-NameSkuWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &-CollectionName,
        &-Name {
            font-size: 1.4rem;
            font-weight: bold;
            color: black;
            text-align: left;
        }

        &-ProductNameString,
        &-FlooringType {
            color: var(--product-card-font-color);
            font-size: 1.4rem;
            padding: 4px 0;
            text-align: left;

            &-Collection {
                font-size: 1.2rem;
                text-align: left;
                color: var(--product-card-font-color);
                padding: 4px 0;
            }
        }

        &-Brand {
            font-size: 1.2rem;
            text-align: left;
            color: var(--product-card-font-color);
            padding: 4px 0;
        }

        &-Sku {
            font-size: 1rem;
            color: var(--product-card-font-color);
        }
    }

    &::before {
        content: none;
    }

    &:last-child {
        @include mobile {
            margin-bottom: .84rem;
        }
    }

    &:hover {
        --wishlist-is-visible: block;
    }

    &_selected {
        &:not(:hover) {
            .Image {
                border: 1px solid var(--related-first-swatch-border-color);
            }
        }

        a {
            pointer-events: none;
        }
    }

    &_block {
        display: block;
    }

    &-Link {
        &_isCrossSell {
            display: flex;
        }

        &_isCartPopup {
            display: block;
        }
    }

    &-Details {
        margin-left: 10px;
        max-width: 250px;

        &_isCartPopup {
            margin-left: 0;
            font-size: 14px;
            padding: 10px 0;
        }
    }

    &-RelatedWrapper,
    &-UpsellWrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        max-width: 140px;
        height: 100%;
    }

    &-RelatedBrandName,
    &-UpsellBrandName,
    &-RelatedCollectionNameColor,
    &-UpsellCollectionNameColor {
        font-size: 1.4rem;
        font-weight: bold;
        color: black;
    }

    &-RelatedSku,
    &-UpsellSku,
    &-RugSize {
        color: var(--option-tooltip-color);
        padding-top: 5px;
    }

    &-CurrentImage {
        width: 24px;
        height: 24px;
        object-fit: cover;
    }

    &-ViewPopupLink {
        color: var(--primary-base-color);
        font-size: 1.2rem;
        margin-left: 8px;
        top: -7px;
    }

    &-WishlistView {
        font-family: var(--option-font-lato-regular);
        font-size: 1.4rem;
        color: var(--option-tooltip-color);
        background-color: var(--option-background-color);
        position: absolute;
        z-index: 10;
        box-shadow: 0 0 14px 0 rgba(0,0,0,.08);
        width: 120px;
        padding: 10px 10px 5px;
        display: var(--wishlist-is-visible);
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
        --swatch-image-size: 70px;

        &:hover {
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.20);
            transition: all .2s ease-out;
        }

        .ProductWishlistButton-AttributeWishlist {
            margin-top: 10px;
        }
    }

    &-related,
    &-upsell {
        background-color: var(--option-background-color);
        display: inline-block;
        min-height: 130px;
        width: 130px;

        img {
            position: relative;
        }
    }

    &_isCrossSellCard {
        display: block;
        width: 100%;
        max-width: 400px;
        padding-left: 20px;
        padding-right: 20px;
    }

    &-Description {
        font-family: var(--product-actions-font-lato-regular);
        font-size: 1.4rem;
        line-height: 30px;

        &_type {
            &_expanded_content {
                display: none;
            }

            &_less,
            &_more {
                color: grey;
                cursor: pointer;
            }
        }
    }

    &-Qty button {
        background-color: var(--button-background);
        padding-bottom: 12px;
    }

    &-QtyWord {
        font-family: var(--product-actions-font-lato-regular);
        font-size: 1.4rem;
        font-weight: 700;
        margin-right: 10px;
    }

    &-Qty {
        margin-top: 0;
        display: inline-block;
        vertical-align: middle;

        [type='number'] ~ button:first-of-type, [type='number'] ~ button:last-of-type {
            height: 20px;

            &:after,
            &:before {
                background-color: white;
            }
        }
    }

    &-AccessoryAddToCart {
        display: none;
    }

    &-AccessoryAddToCartWrapper {
        display: none;

        &_isAddToCartVisible {
            display: block;
        }

        &_isCartPopup {
            max-width: 70%;
        }
    }

    &-QuantityConfirmWrapper {
        display: none;

        &_isCustomQtyInputVisible {
            display: block;
        }
    }

    &-AccessoryAddToCartButton {
        right: 0;
        color: #FFFFFF;
        font-family: Lato;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 13px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 3px;
        border: none;
        padding: 6.5px 10px;
        margin: 0;
        margin-top: 10px;
        height: 32px;
    }

    &-AddToCartQty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border: 1px solid var(--product-actions-input-border-color);
        border-radius: 4px;

        &_isPremiumCustomer {
            display: none;
        }

        input {
            width: 36px;
            height: 17px;
            background: transparent;
            border: 0;
            font-weight: 400;
            text-align: center;
            padding: 0;
            order: 2;
            font-size: 1.6rem;
            margin: 0;

            &:focus,
            :hover {
                border: 0;
            }
        }

        button {
            position: relative;
            left: unset;
            right: unset;
            top: 0;

            &:first-of-type {
                order: 3;
            }

            &:last-of-type {
                order: 1;
            }
        }

        &_isConfirm {
            input {
                width: 88px;
            }

            button {
                display: none;
            }
        }
    }

    &-Tooltip {
        display: none;
        position: absolute;
        top: -27px;
        right: 5px;
        width: max-content;
        border-radius: 4px;
        background-color: #000000;
        padding: 2px;

        &:before {
            content: "";
            position: absolute;
            top: 100%;
            right: 7px;
            width: 0;
            border-top: 5px solid black;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }

        &_isVisible {
            display: block;
        }
    }

    &-TooltipText {
        color: #FFFFFF;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0.32px;
        line-height: 16px;
        padding: 5px;
    }

    &-ConfirmButton,
    &-CancelButton {
        display: none;
        position: absolute;
        height: 20px;
        width: 20px;
        cursor: pointer;

        &_isVisible {
            display: block;
        }
    }

    &-CancelButton {
        top: 6px;
        left: 6px;
        font-weight: 200;
    }

    &-ConfirmButton {
        top: 6px;
        right: 8px;
        color: #E53F25;
    }

    &-AddToCart {
        text-transform: none;
        font-weight: 600;
        font-size: 1.3rem;
        border-radius: 3px;
        padding: 6.5px 10px;
        border: none;

        @include before-desktop {
            flex-grow: 1;
            padding: 15px
        }

        &_linkType {
            &_crosssell {
                min-width: auto;
            }
        }
    }

    &-QtyWrapper {
        float: left;
        margin-top: 20px;
    }

    &-CrossSellPriceWrapper {
        display: inline-flex;
        padding-left: 10px;
        position: absolute;
        bottom: 0;
    }

    &-Header {
        display:flex;
        flex-flow:row;
        justify-content: space-between;
        margin-bottom: 5px;

        &-CollectionName {
            color: #1d2d44;
            font-size: 15.5px;
            font-weight: 700;
            align-self: flex-start;
        }

        &-PlankWidth {
            color: var(--product-card-text-color);
            font-size: 12px;
            align-self: flex-end;
            margin-bottom: auto;
        }

        &-RequiredAccessory {
            color: var(--primary-base-color);;
            font-weight: 700;
            font-size: 1.2rem;
            text-align: center;
            margin: auto;
            padding: 10px 0;
            height: 50px;
        }
    }

    &-FlooringType,
    &-AccessorySize {
        color: var(--product-card-text-color);
    }

    &-Content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 16px;
        text-align: right;

        .ProductSwatchPopup-SwatchPopupTriggerCheckbox {
            display: none;
        }

        input + .ProductSwatchPopup-Popup_isCrawler {
            display: none;
            opacity: 0;
        }
        input:checked + .ProductSwatchPopup-Popup_isCrawler {
            display: block;
            opacity: 1;
        }

        &_isCrossSell {
            display: block;
            flex-grow: 0;
            padding: 0;
            margin-left: auto;
            min-width: fit-content;
        }

        &_isCartPopup {
            text-align: left;
        }

        &_layout_list {
            justify-content: space-between;
            margin-left: 20px;
            width: 100%;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-right: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-bottom: 0;
                    }

                    .ProductCompareButton-Button {
                        height: 42px;
                        width: 42px;
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                }

                &-WishListButton {
                    height: 42px;
                    width: 42px;
                }
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        display: none;
    }

    &-Brand {
        color: var(--product-card-text-color);
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 1.1rem;
        display:flex;
        height: 32px;
        overflow: hidden;

        @include after-mobile {
            font-size: 1.2rem;
        }
    }

    &-Length {
        color: var(--product-card-text-color);

        p {
            margin-bottom: 0;
            line-height: normal;
        }
    }

    &-ShowMoreButton {
        color: var(--primary-base-color);

        &::after {
            content: "";
            border-width: 0 0 1px 1px;
            border-style: solid;
            height: 6px;
            width: 6px;
            position: absolute;
            right: 30%;
            top: 50%;
            transform: translate(calc(100% + 5px),-75%) rotate(-45deg);
            transition: .5s;

            @include tablet {
                right: 25%;
            }

            @include mobile {
                right: 40%;
            }
        }

        &_state {
            &_isOpen {
                &::after {
                    transform: translate(calc(100% + 5px), -25%) rotate(135deg);
                }
            }
        }
    }

    &-PriceWishlistWrapper {
        display: inline-flex;
        justify-content: space-between;
    }

    &-BrandWishlistWrapper {
        display: inline-flex;
        justify-content: space-between;
    }

    &-BrandValueWrapper {
        margin-bottom: 10px;
        max-width: 90%;
    }

    &-BrandAttributeValueBrand {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-size: 1.2rem;
        color: var(--product-card-text-color);
    }

    &-BrandAttributeValueColor {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-size: 1.2rem;
        color: var(--product-card-text-color);
    }

    &-Picture {
        padding-bottom: 105%;
    }

    &-crosssell {
        height: 68px;
        width: 68px;

        img {
            max-width: fit-content;
            max-height: 100px;
        }

        &_isCartPopup {
            width: 100%;
            height: 150px;

            .Image-Image {
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &-CrossSellPrice {
        font-weight: 500;
        font-size: 1.6rem;

        &_isCartPopup {
            text-align: left;
            color: #000000;
            font-family: Lato;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 22px;
        }

        &_isHidden {
            display: none;
        }
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        bottom: 0;
        margin: auto;
        top: 0;
    }

    &-FigureReview {
        display: flex;
        justify-content: center;
        flex-direction: column;

        &_isAccessory {
            .Image-Image {
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
            }
        }
    }

    &-Reviews {
        --product-rating-size: 1.6rem;
        --product-rating-background: #ffff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 105px;
        height: 25px;
        position: absolute;
        background-color: var(--product-card-reviews-background);
        border-radius: 1rem;
        letter-spacing: normal;

        &_layout_grid {
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }

        @include mobile {
            --product-rating-size: 1.4rem;

            width: 100%;
            border-radius: 0;
        }
    }

    &-ShowMoreSwatches{
        @include mobile {
            text-align: left;
        }
    }

    &-ConfigurableOptions {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 0.25rem;
        min-height: calc(var(--product-card-color-magnified-size) + 5px);
        margin-top: 10px;

        @include mobile {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }

        li {
            list-style-type: none;
            padding-left: 0;
            width: calc(var(--product-card-color-size) + 5px);
            height: calc(var(--product-card-color-size) + 5px);

            @include mobile {
                width: calc(var(--product-card-color-size) + 20px);
                height: calc(var(--product-card-color-size) + 20px);
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:before,
            &:after {
                content: none;
            }

            div {
                height: inherit;
            }
        }
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    li {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &-ConfigurableOptionsLabel {
        font-weight: 500;
        color: var(--secondary-base-color);
        font-size: 0.8em;
        position: absolute;
        bottom: 16px;
        left: 12px;
        width: fit-content;
        background-color: white;
        padding: 8px 8px 8px 8px;
        cursor: pointer;
        z-index: 11;
    }

    &-Color,
    &-Image {
        object-fit: cover;
        display: inline-block;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &_isSelected {
            padding: 2px;
            border: 1px solid black;

            &:hover {
                border: 0;
                padding: 0;
            }
        }
    }

    &-Image {
        &:hover {
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: 10;
            width: var(--product-card-color-magnified-size);
            height: var(--product-card-color-magnified-size);
            @include mobile {
                width: calc(var(--product-card-color-magnified-size) + 15px);
                height: calc(var(--product-card-color-magnified-size) + 15px);
            }
        }
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 5px 7px 0 0;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 18px 0;

        &_isCrossSellCard,
        &_isAccessoriesPrice {
            display: block;
            padding: 0;
        }

        &_isRelated,
        &_isUpsell {
            flex-direction: column;
            align-items: center;
            row-gap: 5px;
            color: black;
            margin-top: auto
        }

        &-Price {
            font-size: 2rem;

            &_isLineThrough {
                text-decoration: line-through;
                font-size: 1.2rem;
                color: var(--product-card-font-color);
            }

            &_isAccessory {
                display: none;
            }

            &_isHidden {
                display: none;
            }
        }

        &-RugPrice {
            &_isNormalPrice {
                font-size: 2rem;
            }

            &_isLineThrough {
                text-decoration: line-through;
                font-size: 1.2rem;
                color: var(--product-card-font-color);
            }

            &_isHidden {
                display: none;
            }
        }

        &-Units,
        &-BoxPrice {
            font-size: 1.2rem;
        }

        &-BoxPrice {
            margin-left: 12px;

            &_isBoxPriceHidden {
                display: none;
            }

            &_isAccessory {
                text-align: left;
                margin-left: 0;
                font-size: 2rem;
            }
        }

        &_isLineThrough {
            padding: 16px 0 18px 0;
        }
    }

    &-SampleAndWishlistWrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    &-WishlistIcon {
        width: 20px;
        height: 20px;
        border: 1px solid var(--primary-base-color);
        border-radius: 5px;
        display: inline-block;
        top: 5px;
        margin-right: 3px;
    }

    &-WishlistIconPlus {
        font-size: 2rem;
        top: -4px;
        left: 3px;
        color: var(--primary-base-color);
    }

    &-Price {
        width: 100%;
        font-weight: 300;
        color: var(--product-card-text-color);

        @include after-mobile {
            font-size: 1.2rem;
        }

        &_isLineThrough {
            text-decoration: line-through;
        }

        &_isNormalPrice {
            color: #1d2d44;
            font-size: medium;
            font-weight: 500;
        }
    }

    &-PriceBadgeWrapper {
        margin-left: 8px;
    }

    &-DiscontinuedBadgeWrapper {
        padding: 16px 0 18px 0;
    }

    &-DiscontinuedBadge {
        font-size: 1.2rem;
        margin-bottom: 0;
        line-height: 20px;
        font-weight: 400;
        padding: 0 4px;
        width: fit-content;
        background-color: #e53f25;
        color: white;
    }

    &-PriceBadge {
        width: fit-content;
        background-color: black;
        color: white;
        font-size: 1.2rem;
        margin-bottom: 0;
        line-height: 20px;
        font-weight: 400;
        padding: 0 4px;
    }

    &-Figure {
        width: auto;

        &_isCrossSellCard {
            text-align: center;
        }
    }

    &-Sku {
        font-weight: 700;
        font-size: 1.2rem;
        font-family: var(--product-card-font-family-lato);
        margin-bottom: 5px;
    }

    &-AccessoriesProductName {
        color: black;
        font-weight: 700;
        font-size: 1.2rem;
        font-family: var(--product-card-font-family-lato);
        margin-bottom: 5px;
        display: block;
    }

    &-ProductName {
        font-size: 1.6rem;
        font-family: var(--product-card-font-family-lato);
        line-height: 18px;
        margin-bottom: 5px;
    }

    &-AccessoriesProductSKU{
        color: var(--product-card-text-color);
        font-size: 1.2rem;
        font-family: var(--product-card-font-family-lato);
        height: 30px;
    }

    &:not(.ProductCard_block) {
        &-Link {
            color: inherit;
            display: flex;
            flex-direction: column;
            width: 100%;
            top: 10px;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    &-ProductActions {
        position: absolute;
        top: 82.5%;
        right: 15px;
        display: flex;
        flex-direction: column;

        &_linkType {
            &_crosssell {
                top: 76%;
            }
        }
    }

    &-WishListButton {
        width: 15px;
        height: 15px;
        background-color: var(--product-card-wishlist-button-background);
        border: none;
        transition: all .2s;

        .ProductWishlistButton-Heart {
            --wishlist-heart-size: 12px;

            transform: scale(.7) rotate(-45deg);
            background-color: var(--product-card-wishlist-button-color);

            @include before-desktop {
                transform: scale(.6) rotate(-45deg);
            }
        }

        ::before,
        ::after {
            background-color: var(--product-card-wishlist-button-color);
        }

        &:hover {
            @include after-mobile {
                opacity: 1;
                transition: all .2s;
            }

            .ProductWishlistButton-Heart {
                @include after-mobile {
                    background-color: var(--primary-base-color);
                }
            }

            ::before,
            ::after {
                @include after-mobile {
                    background-color: var(--primary-base-color);
                }
            }
        }

        &:not([disabled]):hover,
        &:not([disabled]):focus {
            background-color: var(--product-card-wishlist-button-background);
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    .ProductWishlistButton {
        margin-top: 0px;
        float: right;

        &-Button_isInWishlist {
            background-color: var(--product-card-wishlist-button-background);
            opacity: 1;

            .ProductWishlistButton-Heart {
                background-color: var(--primary-base-color);
            }

            ::before,
            ::after {
                background-color: var(--primary-base-color);
            }
        }
    }

    .ProductCompareButton {
        display: none;

        &-Button {
            width: 35px;
            height: 35px;
            border: none;
        }

        &-Icon {
            height: 23px;
            margin: auto;

            &::before {
                width: 5px;
                left: -5px;
            }

            &::after {
                width: 5px;
            }
        }
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }

    &-DiscontinuedBanner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 10;
    }
}

.CategoryProductList-Page {
    .ProductCard {
        &-PriceWrapper {
            &-Price {
                &_isAccessory {
                    margin-top: 10px;
                    text-align: left;
                    display: block;
                }
            }
        }
    }
}
